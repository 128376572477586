/* eslint-disable max-len */
import React, { Component } from 'react';
import { CancelToken } from 'axios';
import {
  Layout, Row, List, Input, Col, Divider, Icon, Button, Tag, Spin,
} from 'antd';
import { generatePath, Link, withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import moment from 'moment';
import debounce from 'lodash.debounce';
import { patientActions } from '../../actions';
import { patientService } from '../../services';
import { dates } from '../../helpers/dates';
import Modal from '../Modal';
import { pathRoutes } from '../../routes';
import { stringsHelp } from '../../helpers';
import ModalCheckInstitution from '../ModalCheckInstitution';
import { mixPanelService } from '../../services/mixpanel.service';

const { Content } = Layout;

class Patients extends Component {
  cancelSource = CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      modalPatient: {},
      loading: false,
      modalVisibleInstitutions: false,
      loadingSearch: false,
      page: 1,
      count: 0,
      // eslint-disable-next-line react/no-unused-state
      limit: 10,
      filterValue: '',
    };
    this.debouncefn = debounce(this.filterPatients, 300);
  }

  // eslint-disable-next-line react/sort-comp
  handleOk = (id) => {
    mixPanelService.tracking(mixPanelService.PACIENTE_EDITAR);
    // eslint-disable-next-line react/destructuring-assignment
    this.props.history.push(generatePath(pathRoutes.editPatient, { id }));
  };

  handleCancel = () => {
    this.setState({ modalVisible: false });
  };

  setModalVisibleCheckInstitution = (modalVisibleInstitutions) => {
    this.setState({ modalVisibleInstitutions });
  };

  componentWillMount() {
    this.setState({ loadingSearch: true });
  }

  async componentDidMount() {
    // A mudanca de rota causada pela funcao changePage aciona um rerender
    // Esse if garante que só mande os dados para o mixpanel após o rerender
    if (this.props.location.pathname === '/pacientes/1') {
      mixPanelService.tracking(mixPanelService.MENU_PACIENTE);
    }

    try {
      await this.fetchPatientCount();
    } catch (error) {
      console.error('Erro ao buscar contagem de pacientes:', error);
    }

    const { page } = this.props.match.params;
    const { filter } = this.props.match.params;

    this.changePage(page || 1, filter);
  }

  filterPatients = () => {
    const { filterValue } = this.state;
    console.log('FILTER VALUE DE FILTER PATIENTS:', filterValue);

    if (filterValue.length > 0) {
      mixPanelService.tracking(mixPanelService.PACIENTE_BUSCAR);
      // Chama a função de mudança de página com o filtro atual
      this.changePage(1, filterValue, this.state.pageSize);
    } else {
      // Se o filtro estiver vazio, exibe todos os pacientes
      this.changePage(1, null, this.state.pageSize);
    }
  };

  changePage = async (page, filter, pageSize) => {
    console.log('ON CHANGE PAGE FILTER:::', filter);

    this.setState({ loading: true });
    this.cancelSource.cancel();
    this.cancelSource = CancelToken.source();

    const { dispatch } = this.props;

    this.page = page;

    try {
      let responseData;
      if (filter) {
        responseData = await dispatch(
          patientActions.getPatients(this.page, filter, pageSize, this.cancelSource.token),
        );
      } else {
        responseData = await dispatch(
          patientActions.getPatients(this.page, filter, pageSize, this.cancelSource.token),
        );
      }

      this.setState({
        loading: false,
        loadingSearch: false,
        pageSize,
        patients: responseData,
        filterValue: filter, // Mantenha o valor do filtro no estado do componente
      });
    } catch (error) {
      console.error('Erro ao obter pacientes:', error);
      this.setState({ loading: false, loadingSearch: false });
    }
  };

  openModal =async (patient) => {
    this.setState({ modalVisible: true });
    const result = await patientService.getPatient(patient.id);
    this.setState({ modalPatient: result });
  };

  routeIsGroup = (route) => {
    if (
      JSON.parse(localStorage.getItem('selected-institution'))
      && JSON.parse(localStorage.getItem('selected-institution')).is_group
    ) {
      this.setState({
        modalVisibleInstitutions: true,
        checkInstituionRoute: 'patient',
      });
    } else {
      this.props.history.push({
        pathname: pathRoutes.newPatient,
      });
    }
  };

  async fetchPatientCount(pageSize) {
    try {
      const count = await patientService.getPatientCount(pageSize);

      this.setState({
        count: count || 0,
      });
    } catch (error) {
      console.error('Erro ao buscar contagem de pacientes:', error);
    }
  }

  onChangeLimit = async (pageSize) => {
    const currentFilter = this.state.filterValue; // Obtendo o filtro atual do estado
    await this.setState({ limit: pageSize }); // Atualiza o estado limit antes de chamar fetchPatientCount
    await this.fetchPatientCount(pageSize); // Agora a chamada está após a atualização de filterValue
    this.changePage(1, currentFilter, pageSize); // Passando o filtro ao mudar o tamanho da página
  };

  render() {
    const { patient } = this.props;
    let data;

    let pagination = false;

    if (patient.items && patient.total) {
      data = patient.items.map((p) => ({ patient: p }));

      pagination = {
        total: patient.total,
        pageSizeOptions: ['10', '30', '50'],
        defaultPageSize: 10,
        pageSize: this.state.limit,
        showSizeChanger: true,
        current: patient.page,
        onChange: (page) => this.changePage(page, this.state.filterValue),
        onShowSizeChange: async (current, size) => {
          await this.setState({ limit: size });
          this.onChangeLimit(size);
        },
      };
    }

    const { modalPatient } = this.state;
    let isGroup = false;

    try {
      isGroup = JSON.parse(
        localStorage.getItem('selected-institution'),
      ).is_group;
    } catch (e) {
      console.log(e);
    }

    return (
      <Content>
        <Row>
          <Col xs={24} sm={0} style={{ marginBottom: '5px' }}>
            <Button
              type="primary"
              size="large"
              shape="circle"
              onClick={() => this.routeIsGroup('patient')}
              className="add-procedure"
            >
              <Icon type="plus" fill="#ffffff" />
            </Button>
          </Col>
          <Col xs={24} sm={9} lg={14}>
            <h1 className="title">Pacientes</h1>
          </Col>

          <Col xs={24} sm={15} lg={10}>
            <Col xs={24} sm={14} md={16} lg={15} xxl={18}>
              <Input
                size="large"
                placeholder="Buscar"
                suffix={
                  <i style={{ color: '#1bbfbb' }} className="icon-search" />
                }
                ref={(input) => (this.filter = input)}
                onChange={(e) => {
                  this.setState({ filterValue: e.target.value });
                  this.debouncefn();
                }}
                disabled={this.state.loadingSearch}
                value={this.state.filterValue}
              />
            </Col>
            <Col xs={0} sm={10} md={8} lg={9} xxl={6}>
              <Button
                type="primary"
                shape="round"
                onClick={() => this.routeIsGroup('patient')}
                className="add-procedure"
              >
                ADICIONAR
              </Button>
            </Col>
          </Col>
        </Row>

        <Row>
          <List
            loading={this.state.loading}
            itemLayout="horizontal"
            dataSource={data}
            size="large"
            pagination={pagination}
            renderItem={(item) => (
              <List.Item
                onClick={() => {
                  mixPanelService.tracking(mixPanelService.PACIENTE_ABRIR);
                  this.openModal(item.patient);
                }}
              >
                <List.Item>
                  <List.Item.Meta
                    title={(
                      <div>
                        <span>
                          {item.patient.preferred_name
                            ? `Nome Social: ${stringsHelp.firstLetterUpper(item.patient.preferred_name, true)} Nome Civil: ${stringsHelp.firstLetterUpper(item.patient.name, true)}`
                            : stringsHelp.firstLetterUpper(item.patient.name, true)}
                          {' '}
                          {item.patient.cpf ? `| ${item.patient.cpf}` : ''}
                          {' '}
                        </span>
                        {isGroup && item.patient.institution_name ? (
                          <span>
                              &nbsp;&nbsp;
                            <Tag
                              className="tag-custom-round"
                              color="rgb(221, 246, 246)"
                              style={{ marginBottom: 3 }}
                            >
                              {' '}
                              <Icon type="home" />
                                &nbsp;&nbsp;
                              <span>
                                {' '}
                                {stringsHelp.maxLength(
                                  stringsHelp.formatName(item.patient.institution_name),
                                  40,
                                  true,
                                )}
                              </span>
                            </Tag>
                          </span>
                        ) : ''}
                      </div>
                      )}
                    description={
                        item.patient.last_procedure && item.patient.last_procedure.date
                          ? `Último procedimento: ${moment(item.patient.last_procedure.date).format('DD/MM/YYYY')} | ${stringsHelp.firstLetterUpper(item.patient.last_procedure.name ? item.patient.last_procedure.name : 'Desconhecido')}`
                          : 'Sem procedimentos'
                      }
                  />

                </List.Item>
              </List.Item>

            )}
            className="scheduling-list list-patients"
          />

          {data && (
          <div style={{ background: '#f5f5f5', padding: '10px' }}>
            <Row>
              <Col span={24}>
                <span style={{ display: 'block', marginBottom: '5px' }}>
                  Total de registros:
                </span>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Tag style={{ fontSize: '13px', width: '100%' }}>{patient.total}</Tag>
              </Col>
            </Row>
          </div>
          )}

        </Row>

        <ModalCheckInstitution
          visible={this.state.modalVisibleInstitutions}
          route="patient"
          setVisible={this.setModalVisibleCheckInstitution}
        />

        <Modal
          title="Paciente"
          visible={this.state.modalVisible}
          onCancel={this.handleCancel}
          onOk={() => this.handleOk(modalPatient.id)}
          buttonOkText="EDITAR"
          buttonOkType="secondary"
        >
          {modalPatient ? (
            <Row>
              <Col span={24} className="modal-scheduling-view">
                <Col span={24} className="modal-scheduling-view-title">
                  <span>Dados</span>
                  {isGroup && modalPatient.institution ? (
                    <span>
                              &nbsp;&nbsp;
                      <Tag
                        className="tag-custom-round"
                        color="rgb(221, 246, 246)"
                        style={{ marginBottom: 3, fontWeight: 500 }}
                      >
                        {' '}
                        <Icon type="home" />
                        &nbsp;&nbsp;
                        <span>
                          {' '}
                          {stringsHelp.maxLength(
                            stringsHelp.formatName(modalPatient.institution.name),
                            40,
                            true,
                          )}
                        </span>
                      </Tag>
                    </span>
                  ) : ''}
                </Col>
                <Col span={24} className="modal-scheduling-view-item">
                  <span className="modal-scheduling-view-item-title">NOME CIVIL</span>
                  <span>
                    {stringsHelp.firstLetterUpper(
                      modalPatient.name ? modalPatient.name : '-',
                      true,
                    )}
                  </span>
                </Col>
                {modalPatient.preferred_name ? (
                  <Col span={24} className="modal-scheduling-view-item">
                    <span className="modal-scheduling-view-item-title">NOME SOCIAL</span>
                    <span>
                      {stringsHelp.firstLetterUpper(
                        modalPatient.preferred_name,
                        true,
                      )}
                    </span>
                  </Col>
                ) : (<></>)}
                <Col xs={24} sm={8} className="modal-scheduling-view-item">
                  <span className="modal-scheduling-view-item-title">
                    NASCIMENTO
                  </span>
                  <span>
                    {modalPatient.birth_date
                      ? moment.utc(
                        modalPatient.birth_date,
                      ).format('DD/MM/YYYY')
                      : '-'}
                  </span>
                </Col>
                <Col xs={24} sm={8} className="modal-scheduling-view-item">
                  <span className="modal-scheduling-view-item-title">IDADE</span>
                  <span>
                    {modalPatient.birth_date
                      ? dates.age(
                        moment.utc(
                          modalPatient.birth_date,
                        ).format('DD/MM/YYYY'),
                      )
                      : '-'}
                  </span>
                </Col>
                <Col xs={24} sm={8} className="modal-scheduling-view-item">
                  <span className="modal-scheduling-view-item-title">CPF</span>
                  <span>{modalPatient.cpf ? modalPatient.cpf : '-'}</span>
                </Col>
                <Col xs={24} sm={8} className="modal-scheduling-view-item">
                  <span className="modal-scheduling-view-item-title">GÊNERO</span>
                  <span>
                    {modalPatient.gender
                      ? modalPatient.gender.name
                      : '-'}
                  </span>
                </Col>
                <Col xs={24} sm={8} className="modal-scheduling-view-item">
                  <span className="modal-scheduling-view-item-title">
                    GRUPO ÉTNICO
                  </span>
                  <span>
                    {modalPatient.ethnic_group
                      ? modalPatient.ethnic_group.name
                      : '-'}
                  </span>
                </Col>
                <Col xs={24} sm={8} className="modal-scheduling-view-item">
                  <span className="modal-scheduling-view-item-title">
                    TELEFONE
                  </span>
                  <span>
                    {modalPatient.phone_number || modalPatient.phone_number_2
                      ? stringsHelp
                        .formatPhoneNumbers(
                          `${modalPatient.phone_number
                          }/${
                            modalPatient.phone_number_2}`,
                        )
                        .join(' / ')
                      : '-'}
                  </span>
                </Col>
                <Col xs={24} sm={8} className="modal-scheduling-view-item">
                  <span className="modal-scheduling-view-item-title">
                    ESTADO CIVIL
                  </span>
                  <span>
                    {modalPatient.marital_status
                      ? modalPatient.marital_status.name
                      : '-'}
                  </span>
                </Col>
                <Col xs={24} sm={16} className="modal-scheduling-view-item">
                  <span className="modal-scheduling-view-item-title">
                    ALERGIAS
                  </span>
                  <span>
                    {modalPatient.allergies ? modalPatient.allergies : '-'}
                  </span>
                </Col>
                <Col xs={24} sm={16} className="modal-scheduling-view-item">
                  <span className="modal-scheduling-view-item-title">EMAIL</span>
                  <span>{modalPatient.email ? modalPatient.email : '-'}</span>
                </Col>
                <Col span={24} className="modal-scheduling-view-item">
                  <span className="modal-scheduling-view-item-title">
                    ENDEREÇO
                  </span>
                  <span>{modalPatient.address ? modalPatient.address : '-'}</span>
                </Col>
                <Col span={24} className="modal-scheduling-view-item">
                  <span className="modal-scheduling-view-item-title">
                    NOME DO RESPONSÁVEL
                  </span>
                  <span>{modalPatient.parent_name ? modalPatient.parent_name : '-'}</span>
                </Col>
              </Col>
              <Divider />
              <Col span={24} className="modal-scheduling-view">
                <Col span={24} className="modal-scheduling-view-title">
                  <span style={{ verticalAlign: 'sub' }}>Procedimentos</span>
                  <Link
                    to={{
                      pathname: pathRoutes.newSchedule,
                      query: {
                        patient: modalPatient,
                        institution: modalPatient.institution,
                      },
                    }}
                    style={{ float: 'right' }}
                    className="ant-btn ant-btn-primary"
                  >
                    ADICIONAR NOVO
                  </Link>
                </Col>
                <Col
                  span={24}
                  className="modal-scheduling-view-item modal-procedures-list"
                >
                  <List
                    itemLayout="horizontal"
                    dataSource={modalPatient.procedures}
                    size="large"
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          title={(
                            <Link
                              to={generatePath(pathRoutes.editSchedule, {
                                id: item.id,
                              })}
                            >
                              {item.name}
                            </Link>
                          )}
                          description={`Realizado em: ${new Date(
                            item.surgery_probable_date,
                          ).toLocaleDateString()}`}
                        />
                      </List.Item>
                    )}
                    className="scheduling-list"
                  />
                </Col>
              </Col>
            </Row>
          ) : (
            <Row style={{ textAlign: 'center', padding: 20 }}>
              <Spin />
            </Row>
          )}
        </Modal>

      </Content>
    );
  }
}

function mapStateToProps(state) {
  const { institutions, patient } = state;

  return {
    patient,
    institutions,
  };
}

const connected = withRouter(connect(mapStateToProps)(Patients));
export default connected;
