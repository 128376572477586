/* eslint-disable no-use-before-define */
import Api from '../helpers/api';

export const sectorsService = {
  getSectors,
  postSector,
  putSector,
  deleteSector,
  getSectorsTotal,
};

function getSectors(filter, page, size, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;

  const pagination = `page=${page}&size=${size}`;
  return Api.get(`/preset/surgery-centers/?${pagination}`, filter, { cancelToken });
}

function getSectorsTotal(selectGroupInstitutionId) {
  return Api.get(
    '/preset/surgery-centers/total',
    getFilter(selectGroupInstitutionId),
  );
}

function postSector(sector){
  return Api.post(`/preset/surgery-centers`, sector)
}

function putSector(sector){
  return Api.put(`/preset/surgery-centers/${sector.uuid}`, sector)
}

function deleteSector(uuid){
  return Api.delete(`/preset/surgery-centers/${uuid}`)
}

function getFilter(selectGroupInstitutionId) {
  if (selectGroupInstitutionId) {
    return {
      params: { institution_id: selectGroupInstitutionId },
    };
  }
  return {};
}
